<template>
  <div v-if="error" class="full-height">
    <v-alert type="error" border="left" text :value="true">
      {{error}}
    </v-alert>
  </div>
  <div v-else class="all-width">
    <v-card>
      <v-card-text>
        <div class="position-sticky">
          <div class="mb-8 d-flex align-center">
            <span class="title font-weight-bold" label>2.1</span>
            <v-icon class="mx-2">mdi-circle-small</v-icon>
            <div class="subtitle-2">Testing Routine list</div>
            <v-spacer></v-spacer>
            <v-btn icon><v-icon>mdi-folder-zip</v-icon></v-btn>
            <v-btn icon><v-icon>mdi-file-excel</v-icon></v-btn>
          </div>
          <div class="d-flex mb-2 align-center">
            <lb-dropdown :items="areadropdownlist" itemvalue="id" label="Filter Area" itemtext="name" v-model="selectedarea" hidedetails/>
            <v-spacer></v-spacer>
            <div>
              <v-chip color="success" small class="">A</v-chip>
              <span class="ml-2 mr-4">Automated Procedure</span>
            </div>
            <div>
              <v-chip color="warning" small class="">M</v-chip>
              <span class="ml-2">Manual Procedure</span>
            </div>
          </div>
        </div>
        <div class="mb-4"></div>
        <v-divider></v-divider>
        <v-simple-table dense class="custom-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th scope="#">#</th>
                <th scope="code">Code</th>
                <th scope="area" v-if="!selectedarea">Area</th>
                <th scope="sub-area">Sub Area</th>
                <th scope="procedure">Procedure</th>
                <th scope="type">Type</th>
                <th scope="status">Status</th>
                <th scope="action">Action</th>
              </tr>
            </thead>
            <tbody>
                <tr v-for="(v, k) in getList" :key="k" class="hoverable py-2">
                  <td class="py-2">{{k+1}}</td>
                  <td>{{(v.verification_detail || {}).code || "M"+v.code}}</td>
                  <td v-if="!selectedarea">{{v.area}}</td>
                  <td>{{v.subarea}}</td>
                  <td>{{(v.verification_detail || {}).name || v.name}}</td>
                  <td>
                    <v-chip v-if="v.__type === 'custom'" color="warning" small class="">M</v-chip>
                    <v-chip v-else small color="success" class="">A</v-chip>
                  </td>
                  <td>
                    <template v-if="v.__type === 'custom'">
                      <v-chip label color="info" small class="my-1">{{"Pending"}}</v-chip>
                    </template>
                    <template v-if="v.__type === 'system'">
                      <v-chip v-if="v.stage===-1" label color="" small class="my-1">{{"Waiting for file"}}</v-chip>
                      <v-chip v-else-if="v.stage===0" label color="info" small class="my-1">{{"Pending"}}</v-chip>
                      <v-chip v-else-if="v.stage===1" label color="success" small class="my-1">{{"Completed"}}</v-chip>
                      <v-chip v-else-if="v.stage===2" label color="error" small class="my-1">{{"Error"}}</v-chip>
                      <v-chip v-else-if="v.stage===4" label color="warning" small class="my-1">{{"Running"}}</v-chip>
                    </template>
                  </td>
                  <td>
                    <v-btn color="primary" small text @click="viewdialog=true;viewarea=v.area;viewsubarea=v.subarea,viewverification=v">View More</v-btn>
                  </td>
                </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-dialog v-model="viewdialog">
          <v-card class="">
            <v-card-text class="primary white--text d-flex align-center py-2 px-4">
              <span class="subtitle-1 white--text">Testing Routine</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="viewdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-text>
            <v-card-text class="mt-6">
              <v-row>
                <v-col cols="4">
                  <div class="mb-4">
                    <div class="title mb-2">{{(viewverification.verification_detail || {}).name || viewverification.name}}</div>
                    <div class="subtitle-1">{{viewarea}}</div>
                    <div class="subtitle-2">{{viewsubarea}}</div>
                  </div>
                  <div class="mb-4 d-flex align-center">
                    <v-chip label class="primary mr-2" small>{{((viewverification.__type === 'custom')?'M':'') + viewverification.code}}</v-chip>
                    <template v-if="viewverification.__type === 'custom'">
                      <v-chip label color="info" small class="my-1">{{"Pending"}}</v-chip>
                    </template>
                    <template v-if="viewverification.__type === 'system'">
                      <v-chip v-if="viewverification.stage===-1" label color="" small class="my-1">{{"Waiting for file"}}</v-chip>
                      <v-chip v-else-if="viewverification.stage===0" label color="info" small class="my-1">{{"Pending"}}</v-chip>
                      <v-chip v-else-if="viewverification.stage===1" label color="success" small class="my-1">{{"Completed"}}</v-chip>
                      <v-chip v-else-if="viewverification.stage===2" label color="error" small class="my-1">{{"Error"}}</v-chip>
                      <v-chip v-else-if="viewverification.stage===4" label color="warning" small class="my-1">{{"Running"}}</v-chip>
                    </template>
                    <v-btn icon small class="ml-2"><v-icon>mdi-file-excel</v-icon></v-btn>
                    <v-spacer></v-spacer>
                  </div>
                  <div class="mb-4">
                    <div class="body-2 font-weight-bold">Description</div>
                    <div>{{(viewverification.verification_detail || {}).description || "No Data!"}}</div>
                  </div>
                  <div class="mb-4">
                    <div class="body-2 font-weight-bold">Observation</div>
                    <div>{{(viewverification.verification_detail || {}).observation || "No Data!"}}</div>
                  </div>
                  <div class="mb-4">
                    <div class="body-2 font-weight-bold">Impact</div>
                    <div>{{(viewverification.verification_detail || {}).impact || "No Data!"}}</div>
                  </div>
                  <div class="mb-4">
                    <div class="body-2 font-weight-bold">Suggestion</div>
                    <div>{{(viewverification.verification_detail || {}).suggestion || "No Data!"}}</div>
                  </div>
                  <div class="mb-4">
                    <div class="body-2 font-weight-bold">Management Response</div>
                    <div>{{(viewverification.verification_detail || {}).suggestion || "No Data!"}}</div>
                  </div>
                </v-col>
                <v-col cols="8">
                  <v-tabs v-model="viewtabvalue" class="" height="35">
                    <v-tab>Summary</v-tab>
                    <v-tab>Annexures</v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="viewtabvalue" class="bg-transperant">
                    <v-tab-item>
                      Summmary
                    </v-tab-item>
                    <v-tab-item>
                      Annexure
                    </v-tab-item>
                  </v-tabs-items>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'assignment_setup_scope',
  data: function(){
    return {
      loading: false,
      selectedarea: "",
      verificationlist: {},
      arealist: [],
      subarealist: {},
      filelist: {},
      viewdialog: false,
      viewarea: {},
      viewsubarea: {},
      viewverification: {},
      viewtabvalue: '',
    }
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  methods: {
    refreshData(full = true) {
      if(full){
        console.log(full);
      }
      this.loading = false;
      this.selectedarea = "";
      this.verificationlist = {};
      this.arealist = [];
      this.subarealist = {};
      this.filelist = {};
      this.viewdialog = false;
      this.viewarea = {};
      this.viewsubarea = {};
      this.viewverification = {};
      this.viewtabvalue = '';
      this.getData();
    },
    getData(){
      this.error = "";
      this.loading = true;
      this.axios.post("/v2/ia/assignment/gettestinglist/"+this.id).then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          let data = dt.data.data[0];
          this.verificationlist = data.verificationlist;
          this.arealist = data.arealist;
          this.subarealist = data.subarealist;
          this.filelist = data.filelist;
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.counter++;
        this.pageloading = false;
      })
    },
  },
  computed: {
    areadropdownlist: function(){
      let list = [];

      for (const i of this.arealist) {
        list.push({id: i._id, name: i.name});
      }
      return list;
    },
    getList: function(){
      let list = [];
      for (let i = 0; i < this.arealist.length; i++) {
        const el = this.arealist[i];
        for (let k = 0; k < (this.subarealist[el._id] || []).length; k++) {
          const sel = this.subarealist[el._id][k] || {};
         
          for (let j = 0; j < this.verificationlist[sel._id].length; j++) {
            const vel = this.verificationlist[sel._id][j];
            let nvel = {...vel};
            nvel.area = el.name;
            nvel.subarea = sel.name;
            list.push(nvel);
          }
        }
      }
      return list;
    }
  },
}
</script>
